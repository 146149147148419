.header {
  padding: 20px;
  border-bottom: 1px solid rgba(17, 20, 24, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header img {
  height: 40px;
  vertical-align: sub;
}

.header .plus {
  margin: 0 15px;
  display: inline-block;
  vertical-align: super;
}
