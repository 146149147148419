.footer {
  position: sticky;
  bottom: 0;
  display: flex;
  width: 100%;
  background-color: rgb(246, 247, 249);
  border-top: 1px solid rgba(17, 20, 24, 0.15);
}

.footer > * {
  flex: 1;
  margin: 10px;
}

.footer .stats {
  text-align: right;
}

.footer .stats > span {
  margin-left: 5px;
  font-size: 0.85em;
  color: rgba(17, 20, 24, 0.6);
  display: inline-block;
  vertical-align: middle;
}

.search > .search-bar > input {
  padding: 5px;
  padding-left: 30px;
  border: 1px solid rgba(17, 20, 24, 0.25);
  border-radius: 5px;
  display: inline-block;
  vertical-align: top;
}

.search > * {
  display: inline-block;
  vertical-align: top;
}

.search .actions {
  margin-left: 5px;
  opacity: 0.5;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.search .actions .disabled {
  opacity: 0.5;
}

.search > .results {
  margin-left: 5px;
  font-size: 0.85em;
  color: rgba(17, 20, 24, 0.6);
  vertical-align: sub;
}

.search .results > span {
  margin-right: 5px;
}

.search-bar::before {
  content: url("/public/search.svg");
  opacity: 0.3;
  margin-top: 2px;
  position: absolute;
  width: 4px;
  margin-left: 3px;
}
