table {
  width: 100%;
  table-layout: fixed;
  min-width: 800px;
  border-collapse: collapse;
  border: 1px solid rgba(17, 20, 24, 0.15);
  border-top: none;
  border-bottom: none;
  font-size: 0.9em;
}

thead tr {
  background-color: rgb(246, 247, 249);
}

thead tr th {
  box-shadow: inset -1px -1px 0 rgba(17, 20, 24, 0.15);
  background-color: #f6f7f9;
  padding: 20px 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: uppercase;
}

thead tr th svg {
  vertical-align: middle;
  height: 18px;
}

td {
  box-shadow: inset 0 -1px 0 rgba(17, 20, 24, 0.15),
    inset -1px 0 0 rgba(17, 20, 24, 0.15);
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

td.search-hit {
  background-color: #f9e795;
}
td.selected {
  background-color: #8aaae540;
}
td.dragged-to {
  background-color: #8aaae540;
}
td.dragged-over {
  background-color: #8aaae540;
}

td > input {
  width: 100%;
  height: 18px;
}
td > span {
  margin: 1px;
}

.copy-wrapper {
  /* Less than elegent solution to assigned correct table height */
  height: calc(100% - 131px);
  width: 100%;
  overflow-x: scroll;
}
