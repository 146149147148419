*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root,
.app {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.app {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI",
    -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  /* overflow: hidden; */
}

.clickable {
  cursor: pointer;
}
